<template>
  <div class="base-date-picker text-left">
    <div>
      <div class="border-b border-0 w-full relative">
        <input
          :value="modelValue"
          type="date"
          class="w-full outline-none p-1 opacity-0 z-50"
          :max="maxdate"
          :min="mindate"
          @input="handleChange"
          @focus="handleFocus"
        />
        <p v-if="modelValue" class="absolute top-1">{{ modelValue }}</p>
        <p v-else class="absolute top-1 text-gray-400">{{ placeholder }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import * as Utils from '@/utils/index';
export default {
  emits: ['focus', 'update:modelValue'],
  name: 'BaseDatePicker',
  props: {
    modelValue: {
      type: String,
    },
    mindate: {
      type: String,
    },
    maxdate: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  methods: {
    // showDatepicker() {
    //   document.getElementById('dateinput').focus();
    // },
    handleChange(event) {
      this.$emit(
        'update:modelValue',
        Utils.formatBirthday(new Date(event.target.value)),
      );
    },
    handleFocus() {
      this.$emit('focus');
    },
  },
};
</script>
<style lang="scss" scoped>
.base-date-picker {
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
  input[type='date']::-webkit-inner-spin-button {
    z-index: 1;
  }

  /* adjust clear button */
  input[type='date']::-webkit-clear-button {
    z-index: 1;
  }
}
</style>
